import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ROUTES from "../../Configs/Routes";

const NodesHead = ({ selectedNode, setSelectedNode }) => {
  const location = useLocation();
  return (
    <div className="nodesHeadWrapper">
      <Row className="gy-4">
        <Col xs={12}>
          <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
            <Link
              to={ROUTES.SWAP}
              className={`nodeLinkBtn ${
                location.pathname === ROUTES.SWAP ? "active" : ""
              }`}
            >
              Swap
            </Link>
            <Link
              to={ROUTES.BUY_NOW}
              className={`nodeLinkBtn ${
                location.pathname === ROUTES.BUY_NOW ? "active" : ""
              }`}
            >
              Buy Nodes
            </Link>
          </div>
        </Col>
        {location.pathname === ROUTES.BUY_NOW && (
          <Col xs={12}>
            <div className="nodeTypeBox justify-content-center">
              <button
                type="button"
                className={`nodeTypeBtn ${
                  selectedNode === "super" ? "active" : ""
                }`}
                onClick={() => setSelectedNode("super")}
              >
                SUPER Nodes
              </button>
              <button
                type="button"
                className={`nodeTypeBtn ${
                  selectedNode === "elite" ? "active" : ""
                }`}
                onClick={() => setSelectedNode("elite")}
              >
                ELITE Nodes
              </button>
              <button
                type="button"
                className={`nodeTypeBtn ${
                  selectedNode === "rare" ? "active" : ""
                }`}
                onClick={() => setSelectedNode("rare")}
              >
                RARE Nodes
              </button>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default NodesHead;
