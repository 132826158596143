import React from "react";
import arrow from "../../Assets/Images/icons/arrow.png";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import { useCaseList } from "../../Configs/constants";

const UsecaseSlider = () => {
  const useItems = {
    0: {
      items: 1,
    },
    991: {
      items: 1.5,
      center: true,
    },
    1199: {
      items: 2,
      center: false,
    },
  };

  return (
    <OwlCarousel
      className="usecaseCarousel owl-theme"
      loop
      autoplay
      autoplaySpeed={3000}
      responsive={useItems}
      margin={20}
      dots={false}
    >
      {useCaseList?.map((item, index) => {
        return (
          <div className="item useCaseDiv">
            <h2 className="mainTitle2 gradientClr mb-3">{item?.title}</h2>
            <p className="mb-4">{item?.description}</p>
            <Link to={item?.link} className="themeBtn mx-auto">
              {item?.btnText} <img src={arrow} alt="" />
            </Link>
          </div>
        );
      })}
    </OwlCarousel>
  );
};

export default UsecaseSlider;
