import React, { useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../Assets/Images/logo.png";
import ROUTES from "../Configs/Routes";
import { Link, NavLink, useLocation } from "react-router-dom";
import arrow from "../Assets/Images/icons/arrow.png";
// import WOW from 'wowjs';

const Header = () => {
  const location = useLocation();

  const navMenu = [
    {
      name: "Home",
      path: ROUTES.HOME,
    },
    {
      name: "About Us",
      path: ROUTES.ABOUT,
    },
    {
      name: "Network",
      path: ROUTES.NETWORK,
      subMenu: [
        { name: "Testnet", path: ROUTES.NETWORK },
        { name: "Mainnet", path: ROUTES.NETWORK },
      ],
    },
    {
      name: "Ecosystem",
      path: ROUTES.ECOSYSTEM,
      subMenu: [
        { name: "DeFI", path: ROUTES.ECOSYSTEM },
        { name: "Bridge", path: ROUTES.ECOSYSTEM },
        { name: "Wallet", path: ROUTES.ECOSYSTEM },
        { name: "Pay", path: ROUTES.ECOSYSTEM },
        { name: "Web3 Exchange", path: ROUTES.ECOSYSTEM },
        { name: "Gaming", path: ROUTES.ECOSYSTEM },
        { name: "AI Trading Bot", path: ROUTES.ECOSYSTEM },
      ],
    },
    {
      name: "Blog",
      path: ROUTES.BLOG,
    },
    {
      name: "Docs",
      path: "/file/Docs/OXODISE-Blockchain-Light-Paper.pdf",
      target: "_blank"
    },
    {
      name: "Become Validator",
      path: ROUTES.BECOME_VALIDATOR,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // useEffect(() => {
  //   const wow = new WOW.WOW({
  //     boxClass: 'wow', // default
  //     animateClass: 'animate__animated', // default
  //     offset: 0, // default
  //     mobile: false, // default
  //     live: true, // default
  //   });
  //   wow.init();
  // }, [])

  return (
    <>
      <Navbar
        expand="xxl"
        className="mainNavbar py-3 py-lg-4 wow animate__animated animate__fadeInDown"
        id="mainNavbar"
      >
        <Container>
          <Navbar.Brand className="py-0">
            <Link to={ROUTES.HOME}>
              <img src={logo} alt="logo" className="headerLogo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto	align-items-center">
              {navMenu.map((item, id) =>
                !item.subMenu ? (
                  <Nav.Link
                    key={id}
                    to={item.path}
                    as={NavLink}
                    className={location.pathname === item.path ? "active" : ""}
                    target={item.target}
                  >
                    {item.name}
                  </Nav.Link>
                ) : (
                  <NavDropdown title={item.name} id="basic-nav-dropdown">
                    {item?.subMenu.map((menu) => (
                      <Nav.Item>
                        <Link to={menu.path}>{menu.name}</Link>
                      </Nav.Item>
                    ))}
                  </NavDropdown>
                )
              )}
            </Nav>
            <Link
              to={ROUTES.BUY_NODE}
              className="themeBtn mx-auto mx-lg-2 mt-2 mt-lg-0"
            >
              Buy Nodes <img src={arrow} alt="" />
            </Link>
            <button
              type="button"
              className="themeBtn headerBtn mx-auto mx-lg-2 mt-2 mt-lg-0"
            >
              Connect Wallet <img src={arrow} alt="" />
            </button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
