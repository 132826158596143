import React from "react";
import { Button, Col, Container, ProgressBar, Row } from "react-bootstrap";
import nodeBanner from "../../Assets/Images/nodeBanner.png";
import node1 from "../../Assets/Images/node1.png";
import node2 from "../../Assets/Images/node2.png";
import { Link } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import arrow from "../../Assets/Images/icons/arrow.png";
import abcChainImg from "../../Assets/Images/abcChainImg.png";
import { FiExternalLink } from "react-icons/fi";
import finalLogo from "../../Assets/Images/icons/final-logo.png";

const BuyNode = () => {
  return (
    <>
      <div className="nodeBanner pt-5 mt-lg-3">
        <Container>
          <h2 className="mainTitle text-center mb-5">
            "Secure Your Stake and Contribute to the Network"
          </h2>

          <Row className="gy-5">
            <Col xs={12}>
              <Row className="g-4">
                <Col md={12} lg={4}>
                  <div className="node-network-card">
                    <div className="card-tag gradientClr">
                      <img src={finalLogo} alt="" />
                    </div>
                    <div className="card-header">Super Nodes</div>
                    <div className="card-body">
                      <div className="node-progress-wrap">
                        <div className="node-progress-line">
                          <span style={{ width: "0%" }}></span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <span>0%</span>
                          <span>100%</span>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Total Node :</p>
                          <span>50</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Available Nodes :</p>
                          <span>25/50</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Per Node Price :</p>
                          <span>20,000 USD</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Required Coins :</p>
                          <span>2,00,000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Allocation :</p>
                          <span>30% From Emissions Rate</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Vesting Time :</p>
                          <span>24 Months</span>
                        </div>
                      </div>

                      <div className="position-relative text-center mt-4">
                        <Link to={ROUTES.BUY_NOW} className="themeBtn">
                          Buy Node Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={4}>
                  <div className="node-network-card">
                    <div className="card-tag gradientClr">
                      <img src={finalLogo} alt="" />
                    </div>
                    <div className="card-header">Elite Nodes</div>
                    <div className="card-body">
                      <div className="node-progress-wrap">
                        <div className="node-progress-line">
                          <span style={{ width: "0%" }}></span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <span>0%</span>
                          <span>100%</span>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Total Node :</p>
                          <span>100</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Available Nodes :</p>
                          <span>50 / 100</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Per Node Price :</p>
                          <span>10,000 USD</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Required Coins :</p>
                          <span>1,00,000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Allocation :</p>
                          <span>30% From Emissions Rate</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Vesting Time :</p>
                          <span>24 Months</span>
                        </div>
                      </div>

                      <div className="position-relative text-center mt-4">
                        <Link to={ROUTES.BUY_NOW} className="themeBtn">
                          Buy Node Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={4}>
                  <div className="node-network-card">
                    <div className="card-tag gradientClr">
                      <img src={finalLogo} alt="" />
                    </div>
                    <div className="card-header">Rare Nodes</div>
                    <div className="card-body">
                      <div className="node-progress-wrap">
                        <div className="node-progress-line">
                          <span style={{ width: "0%" }}></span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <span>0%</span>
                          <span>100%</span>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Total Node :</p>
                          <span>200</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Available Nodes :</p>
                          <span>50 / 200</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Per Node Price :</p>
                          <span>5,000 USD</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Required Coins :</p>
                          <span>50,000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Allocation :</p>
                          <span>30% From Emissions Rate</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <p className="m-0">Vesting Time :</p>
                          <span>24 Months</span>
                        </div>
                      </div>

                      <div className="position-relative text-center mt-4">
                        <Link to={ROUTES.BUY_NOW} className="themeBtn">
                          Buy Node Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <div className="rare-node-card">
                <div className="card-column">
                  <div className="d-flex align-items-center gap-3">
                    <img src={abcChainImg} alt="" className="icon" />
                    <h3 className="m-0">Oxodise Rare Node</h3>
                  </div>
                </div>
                <div className="card-column">
                  <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-lg-5 px-4">
                    <div className="details">
                      <p>Staked coin</p>
                      <h5>0</h5>
                    </div>
                    <div className="details">
                      <p>My Positions</p>
                      <h5>0 Node pool</h5>
                    </div>
                    <div className="details">
                      <p>Maturity Period</p>
                      <h5>0 Months</h5>
                    </div>
                    <div className="details">
                      <p>Mature date</p>
                      <h5>-</h5>
                    </div>
                  </div>
                </div>
                <div className="card-column">
                  <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-lg-5 px-4">
                    <div className="">
                      <p className="m-0">Earned Reward</p>
                      <h3>0</h3>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <Button type="button">Connect Wallet</Button>
                      <Button type="button">Claim Rewards</Button>
                    </div>
                  </div>
                </div>
                <div className="card-column">
                  <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-4 px-lg-5">
                    <div className="position-relative">
                      See coin info <FiExternalLink color="#08A045" />
                    </div>
                    <div className="position-relative">
                      View contract <FiExternalLink color="#08A045" />
                    </div>
                    <div className="position-relative">
                      Recent Transection <FiExternalLink color="#08A045" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="nodeSec pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-5">
            Node Details and Benefits
          </h2>

          <Row className="gy-4">
            <Col xs={12}>
              <h5 className="gradientClr">Benefits:</h5>
              <ul>
                <li>
                  Passive Income: Earn rewards for participating in the network.
                </li>
                <li>
                  Early Access: Priority access to future offerings and
                  exclusive Reward from Mining.
                </li>
                <li>
                  Governance Rights: when we introduce our Governance protocol
                  Users can Participate in decision-making processes and vote on
                  network upgrades or future proposals in ecosystems.
                </li>
              </ul>
            </Col>
            <Col xs={12}>
              <h5 className="gradientClr">
                Why Should You Consider Buying a Node?
              </h5>
              <ul>
                <li>
                  Passive Income: Earn regular rewards for your participation.
                </li>
                <li>
                  Early Adoption Benefits: Get involved in a promising project
                  at an early stage.
                </li>
                <li>
                  Contributing to Decentralization: Support the growth and
                  security of a decentralized ecosystem.
                </li>
                <li>
                  Influence Over Project Development: Exercise voting rights and
                  participate in key decisions
                </li>
              </ul>
              <Link to="" className="themeBtn">
                Learn More <img src={arrow} alt="" />
              </Link>
            </Col>
            <Col xs={12}>
              <h5 className="gradientClr">What Does Purchasing a Node Mean?</h5>
              <p>
                Purchasing a node is a way to actively participate in and
                support the decentralized network of a blockchain project. By
                buying a node, you are essentially acquiring a stake in the
                network’s infrastructure. Nodes play a crucial role in
                maintaining the blockchain by validating transactions, storing
                data, and securing the network. As a node operator, you
                contribute to the overall health, stability, and efficiency of
                the blockchain.
              </p>
            </Col>
            <Col xs={12}>
              <h5 className="gradientClr">
                How Does a Node Contribute to the Network?
              </h5>
              <ol>
                <li>
                  Transaction Validation: Nodes are responsible for verifying
                  and validating transactions on the blockchain. Every
                  transaction needs to be checked and confirmed before being
                  added to the blockchain. Your node helps ensure that only
                  legitimate transactions are recorded, preventing fraud and
                  double-spending.
                </li>
                <li>
                  Network Security: Nodes are vital to the security of the
                  blockchain. By running a node, you are helping to protect the
                  network against malicious attacks, such as 51% attacks or
                  other security threats. The more nodes that are active, the
                  more decentralized and secure the network becomes.
                </li>
                <li>
                  Decentralization: Blockchain technology relies on
                  decentralization to function effectively. By distributing
                  nodes across various operators, the network remains
                  decentralized, reducing the risk of any single point of
                  failure. Your node adds to this decentralization, making the
                  network more resilient and reliable.
                </li>
                <li>
                  Data Storage and Integrity: Nodes store a copy of the
                  blockchain’s data. This includes all historical transactions,
                  blocks, and records. By operating a node, you help maintain
                  the integrity and accessibility of this data, ensuring that
                  it’s always available and accurate.
                </li>
                <li>
                  Consensus Mechanism Participation: Depending on the
                  blockchain’s consensus mechanism (e.g., Proof of Stake, Proof
                  of Authority), nodes are involved in reaching an agreement on
                  the state of the blockchain. As a node operator, you may
                  participate in decision-making processes that determine how
                  the network evolves and grows.
                </li>
                <li>
                  Earning Rewards: In return for your contribution, node
                  operators typically earn rewards in the form of
                  cryptocurrency. These rewards can come from transaction fees,
                  block rewards, or staking incentives, depending on the
                  network’s model. This creates a passive income opportunity
                  while supporting the network.
                </li>
                <li>
                  Governance and Voting Rights: In many blockchain networks,
                  node operators are granted governance rights. This means you
                  can vote on protocol upgrades, changes, and other decisions
                  that impact the future of the network. Your participation
                  helps shape the project’s direction and ensures the
                  community’s voice is heard.
                </li>
              </ol>
            </Col>
            <Col xs={12}>
              <h5 className="gradientClr">Terms and Conditions</h5>
              <p>
                Lock-up Period All funds or nodes are subject to a lock-up
                period of 24 months. During this time, the funds or nodes cannot
                be transferred, or liquidated. This lock-up period is in place
                to ensure the stability and growth of the project.
              </p>
            </Col>
            <Col xs={12}>
              <h5 className="gradientClr">Refund Policy</h5>
              <p>
                If a refund is granted under specific conditions, please note
                that a deduction of 20% will be applied to the original capital.
                This deduction is necessary to cover administrative and
                processing costs associated with the refund. Refunds are subject
                to the terms outlined at the time of the request.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BuyNode;
