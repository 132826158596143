import React from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ROUTES from "../../Configs/Routes";

const IcoRounds = () => {
  return (
    <Tabs
      defaultActiveKey="SeedFund"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="SeedFund" title="Seed Fund">
        <Row className="g-4 justify-content-center">
          <Col md={6} lg={4}>
            <div className="exchangeDiv">
              <h3 className="d-flex justify-content-between fs-4 text-uppercase">
                Round 1 <span>Soon Launch</span>
              </h3>
              <div className="icoDates d-flex justify-content-center gap-3 p-3 pb-2">
                <Link to={ROUTES.HOME} className="themeBtn">
                  Start Date <span>1<sup>st</sup> Oct, 2024</span>
                </Link>
                <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
                  End Date <span>15<sup>th</sup> Oct, 2024</span>
                </Link>
              </div>
              <div className="d-flex justify-content-between gap-4 p-3">
                <div style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                  <p>Token Allocation :</p>
                  <p>Price Per Token :</p>
                  <p>Vesting Schedule :</p>
                  <p>Fundraising Goal :</p>
                  <p>Payment Methods :</p>
                </div>
                <div style={{ fontSize: "14px", textAlign: "right" }}>
                  <p>3 Million</p>
                  <p>$0.10</p>
                  <p>24 Months</p>
                  <p>$3,00,000.00</p>
                  <p>USDT</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="exchangeDiv">
              <h3 className="d-flex justify-content-between fs-4 text-uppercase">
                Round 2 <span>Soon Launch</span>
              </h3>
              <div className="icoDates d-flex justify-content-center gap-3 p-3 pb-2">
                <Link to={ROUTES.HOME} className="themeBtn">
                  Start Date <span>20<sup>th</sup> Oct, 2024</span>
                </Link>
                <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
                  End Date <span>6<sup>th</sup> Nov, 2024</span>
                </Link>
              </div>
              <div className="d-flex justify-content-between gap-4 p-3">
                <div style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                  <p>Token Allocation :</p>
                  <p>Price Per Token :</p>
                  <p>Vesting Schedule :</p>
                  <p>Fundraising Goal :</p>
                  <p>Payment Methods :</p>
                </div>
                <div style={{ fontSize: "14px", textAlign: "right" }}>
                  <p>3 Million</p>
                  <p>$0.11</p>
                  <p>24 Months</p>
                  <p>$3,30,000.00</p>
                  <p>USDT</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="exchangeDiv">
              <h3 className="d-flex justify-content-between fs-4 text-uppercase">
                Round 3 <span>Soon Launch</span>
              </h3>
              <div className="icoDates d-flex justify-content-center gap-3 p-3 pb-2">
                <Link to={ROUTES.HOME} className="themeBtn">
                  Start Date <span>10<sup>th</sup> Nov, 2024</span>
                </Link>
                <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
                  End Date <span>25<sup>th</sup> Dec, 2024</span>
                </Link>
              </div>
              <div className="d-flex justify-content-between gap-4 p-3">
                <div style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                  <p>Token Allocation :</p>
                  <p>Price Per Token :</p>
                  <p>Vesting Schedule :</p>
                  <p>Fundraising Goal :</p>
                  <p>Payment Methods :</p>
                </div>
                <div style={{ fontSize: "14px", textAlign: "right" }}>
                  <p>3 Million</p>
                  <p>$0.12</p>
                  <p>24 Months</p>
                  <p>$3,60,000.00</p>
                  <p>USDT</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="IEOSale" title="IEO Sale">
        <div className="text-center">No Data Available!</div>
      </Tab>
    </Tabs>
  );
};

export default IcoRounds;
