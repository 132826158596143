import React from "react";
import arrow from "../../Assets/Images/icons/arrow.png";
import { Col, Row, Table } from "react-bootstrap";
import assetOne from "../../Assets/Images/icons/asset-1.png";
import assetTwo from "../../Assets/Images/icons/asset-2.png";
import assetThree from "../../Assets/Images/icons/asset-3.png";

const BecomeValidator = () => {
  const tableData = [
    {
      id: 1,
      validator: "OXODISE-2",
      nodes: "2",
      oxodiseStake: "111,678,319",
      votingWeight: "19.12%",
      commission: "5%",
      maturity: "Aug 27, 2026",
      status: "active",
    },
    {
      id: 2,
      validator: "OXODISE-1",
      nodes: "5",
      oxodiseStake: "111,678,319",
      votingWeight: "19.12%",
      commission: "5%",
      maturity: "Aug 27, 2026",
      status: "active",
    },
    {
      id: 3,
      validator: "BlockVision",
      nodes: "3",
      oxodiseStake: "111,678,319",
      votingWeight: "19.12%",
      commission: "5%",
      maturity: "Aug 27, 2026",
      status: "active",
    },
    {
      id: 4,
      validator: "MetaOps",
      nodes: "1",
      oxodiseStake: "111,678,319",
      votingWeight: "19.12%",
      commission: "5%",
      maturity: "Aug 27, 2026",
      status: "active",
    },
  ];
  return (
    <React.Fragment>
      <div className="newsCompanySec mt-xxl-5 pt-5">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-5">
            <h2 className="mainTitle gradientClr">All Validators</h2>
            <div className="search-input">
              <input
                type="text"
                name=""
                id=""
                placeholder="Search by Account, Transaction, Block an..."
              />
              <span>
                <img src={arrow} alt="" />
              </span>
            </div>
          </div>

          <Row className="g-4 mb-4">
            <Col sm={12} md={6} lg={3}>
              <div className="become-validator-card">
                <p className="gradientClr">Total Validators</p>
                <h5>350</h5>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="become-validator-card">
                <p className="gradientClr">Total OXODISE Stake</p>
                <h5>584,217,281</h5>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="become-validator-card">
                <p className="gradientClr d-flex flex-wrap align-items-center justify-content-between gap-2">
                  Total Supply <span>34%</span>
                </p>
                <h5>4,37999999.00</h5>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="become-validator-card">
                <p className="gradientClr">Avg APY</p>
                <h5>17%</h5>
              </div>
            </Col>
          </Row>

          <div className="become-validator-card">
            <Table borderless hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Validator</th>
                  <th>Nodes</th>
                  <th>OXODISE Staked</th>
                  <th>Pool Weight</th>
                  <th>Est APR</th>
                  <th>Maturity</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.id === 1 || item.id === 2 || item.id === 3 ? (
                        <img
                          src={
                            item.id === 1
                              ? assetOne
                              : item.id === 2
                              ? assetTwo
                              : item.id === 3
                              ? assetThree
                              : null
                          }
                          alt=""
                        />
                      ) : (
                        item.id
                      )}
                    </td>
                    <td>{item.validator}</td>
                    <td>{item.nodes}</td>
                    <td>{item.oxodiseStake}</td>
                    <td>{item.votingWeight}</td>
                    <td>{item.commission}</td>
                    <td>{item.maturity}</td>
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <span
                          className={`status-icon ${
                            item.status === "active" ? "active" : "deactive"
                          }`}
                        ></span>
                        {item.status}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BecomeValidator;
