import React from "react";
import OwlCarousel from "react-owl-carousel";
import benzinga from "../../Assets/Images/news/benzinga.png";
import investing from "../../Assets/Images/news/investing.png";
import msbing from "../../Assets/Images/news/msbing.png";
import market from "../../Assets/Images/news/market.png";
import yahoo from "../../Assets/Images/news/yahoo.png";
import accesswire from "../../Assets/Images/news/accesswire.png";
import morningstar from "../../Assets/Images/news/morningstar.png";
import who13 from "../../Assets/Images/news/who13.png";
import fox8 from "../../Assets/Images/news/fox8.png";
import business from "../../Assets/Images/news/business.png";
import street from "../../Assets/Images/news/street.png";
import fox40 from "../../Assets/Images/news/fox40.png";
import digital from "../../Assets/Images/news/digital.png";
import KMLK from "../../Assets/Images/news/KMLK.png";

const NewsSlider = () => {
  const newsList = [
    benzinga,
    investing,
    msbing,
    market,
    yahoo,
    accesswire,
    morningstar,
    who13,
    fox8,
    business,
    street,
    fox40,
    digital,
    KMLK,
  ];

  const options = {
    rtl: true,
    loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    smartSpeed: 5000,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 2,
      },
      767: {
        items: 3,
      },
      991: {
        items: 3.5,
      },
      1199: {
        items: 4,
      },
      1599: {
        items: 4.5,
      },
    },
  };

  return (
    <OwlCarousel className="owl-theme" {...options}>
      {newsList.map((item, index) => {
        return (
          <div className="item newsSlideDiv" key={index}>
            <img src={item} alt="" />
          </div>
        );
      })}
    </OwlCarousel>
  );
};

export default NewsSlider;
