import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FiExternalLink } from "react-icons/fi";
import abcChainImg from "../../Assets/Images/abcChainImg.png";
import NodesHead from "../../Components/NodesHead/NodesHead";

const Swap = () => {
  const [swapValue, setSwapValue] = useState(0);
  const handleChange = (e) => {
    const input = e.target.value;
    const swappedValue = input / 0.1;
    setSwapValue(swappedValue);
  };
  return (
    <>
      <div className="nodeBanner pt-5 mt-lg-3">
        <Container>
          <Row className="gy-5">
            <Col xs={12} lg={6} className="mx-auto">
              <NodesHead />
              <div className="swap-card">
                <Row className="gy-4">
                  <Col xs={12}>
                    <div className="swapLabelsWrap">
                      <div className="title">Swap From</div>
                      <div className="balance">
                        <span>Balance:-$10,000</span>
                        <button type="button">Max</button>
                      </div>
                    </div>
                    <div className="swapBox">
                      <button type="button">
                        <img
                          src={require("../../Assets/Images/icons/usdt.png")}
                          alt=""
                        />
                        <div className="title">
                          <span>USDT</span>
                          BEP20
                        </div>
                      </button>
                      <input
                        type="number"
                        placeholder="Enter Amount"
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12}></Col>
                  <Col xs={12}>
                    <div className="swapLabelsWrap">
                      <div className="title">Swap To</div>
                      <div className="balance">
                        <span>Balance:1000 OXE</span>
                        <button type="button" className="invisible">
                          Max
                        </button>
                      </div>
                    </div>
                    <div className="swapBox">
                      <button type="button">
                        <img
                          src={require("../../Assets/Images/icons/oxe.png")}
                          alt=""
                        />
                        <div className="title">
                          Token
                          <span>OXE</span>
                        </div>
                      </button>
                      <input
                        type="number"
                        value={swapValue}
                        placeholder="Enter Amount"
                        readOnly
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="details-box">
                      <Row className="gy-3">
                        <Col xs={12}>
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <span>Exchange Rate</span>
                            <span>$ 0.10</span>
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <span>Slippage</span>
                            <span>1%</span>
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <span>Minimum Receive</span>
                            <span>10000 OXE</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="text-center">
                      <button type="button" className="swap-button">
                        Swap
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12}>
              <div className="rare-node-card">
                <div className="card-column">
                  <div className="d-flex align-items-center gap-3">
                    <img src={abcChainImg} alt="" className="icon" />
                    <h3 className="m-0">Purchased History</h3>
                  </div>
                </div>
                <div className="card-column">
                  <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-lg-5 px-4">
                    <div className="details">
                      <p>Staked coin</p>
                      <h5>2,00,000</h5>
                    </div>
                    <div className="details">
                      <p>My Positions</p>
                      <h5>1 Node pool</h5>
                    </div>
                    <div className="details">
                      <p>Maturity Period</p>
                      <h5>24 Months</h5>
                    </div>
                    <div className="details">
                      <p>Mature date</p>
                      <h5>12-12-2024</h5>
                    </div>
                  </div>
                </div>
                <div className="card-column">
                  <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-lg-5 px-4">
                    <div className="">
                      <p className="m-0">Earned Reward</p>
                      <h3>10000.00</h3>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <Button type="button">Connect Wallet</Button>
                      <Button type="button">Claim Rewards</Button>
                    </div>
                  </div>
                </div>
                <div className="card-column">
                  <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-4 px-lg-5">
                    <div className="position-relative">
                      See coin info <FiExternalLink color="#08A045" />
                    </div>
                    <div className="position-relative">
                      View contract <FiExternalLink color="#08A045" />
                    </div>
                    <div className="position-relative">
                      Recent Transection <FiExternalLink color="#08A045" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Swap;
