import React, { useEffect, useState } from "react";

const DaysCounter = () => {
  const [targetDate] = useState(new Date("Nov 01, 2024, 11:00:00").getTime());
  const [remainingDays, setRemainingDays] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      days = days < 10 ? "0" + days : days;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setRemainingDays({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      if (distance < 0) {
        setRemainingDays({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);
  return (
    <span>
      {remainingDays.days}d : {remainingDays.hours}h : {remainingDays.minutes}m
      : {remainingDays.seconds}s
    </span>
  );
};

export default DaysCounter;
