import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContextState from "./Configs/Context/ContextState";
import Layout from "./Components/Layout";
import Home from "./Pages/Home";
import ROUTES from "./Configs/Routes";
import PageNotFound from "./Pages/PageNotFound";
import AboutUs from "./Pages/AboutUs";
import Network from "./Pages/Network";
import Ecosystem from "./Pages/Ecosystem";
import News from "./Pages/News";
import NewsDetails from "./Pages/News/NewsDetails";
import BuyNode from "./Pages/BuyNode";
import BuyNow from "./Pages/BuyNow/BuyNow";
import BecomeValidator from "./Pages/BecomeValidator/BecomeValidator";
import Swap from "./Pages/Swap";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ContextState>
          <Routes>
            <Route element={<Layout />}>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.ABOUT} element={<AboutUs />} />
              <Route path={ROUTES.NETWORK} element={<Network />} />
              <Route path={ROUTES.ECOSYSTEM} element={<Ecosystem />} />
              <Route path={ROUTES.BLOG} element={<News />} />
              <Route path={ROUTES.BLOG_DETAIL} element={<NewsDetails />} />
              <Route path={ROUTES.BUY_NODE} element={<BuyNode />} />
              <Route path={ROUTES.BUY_NOW} element={<BuyNow />} />
              <Route
                path={ROUTES.BECOME_VALIDATOR}
                element={<BecomeValidator />}
              />
              <Route path={ROUTES.SWAP} element={<Swap />} />

              <Route path={"/*"} element={<PageNotFound />} />
            </Route>
          </Routes>

          <ToastContainer />
        </ContextState>
      </BrowserRouter>
    </div>
  );
}

export default App;
