import React from 'react'
import { Col, Row } from 'react-bootstrap'

const NetworkTransData = () => {
  return (
    <>
      <Row className='g-4'>
        <Col sm={6} lg={3} className='networkTransDiv'>
          <div>
            <h3 className='mainTitle2 gradientClr'>30 SEC</h3>
            <p className='mb-0'>Block Time</p>
          </div>
        </Col>
        <Col sm={6} lg={3} className='networkTransDiv'>
          <div>
            <h3 className='mainTitle2 gradientClr'>3 GWEI</h3>
            <p className='mb-0'>Transaction Fee</p>
          </div>
        </Col>
        <Col sm={6} lg={3} className='networkTransDiv'>
          <div>
            <h3 className='mainTitle2 gradientClr'>10000 TSP ON DEVNET</h3>
            <p className='mb-0'>Transaction Throughput</p>
          </div>
        </Col>
        <Col sm={6} lg={3} className='networkTransDiv'>
          <div>
            <h3 className='mainTitle2 gradientClr'>5 TO 30 SEC</h3>
            <p className='mb-0'>Transaction Finality</p>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default NetworkTransData