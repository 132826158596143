import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { FaPlay } from "react-icons/fa6";
import NodesHead from "../../Components/NodesHead/NodesHead";
import { FiExternalLink } from "react-icons/fi";
import abcChainImg from "../../Assets/Images/abcChainImg.png";
import { Link } from "react-router-dom";
import DaysCounter from "../../Components/DaysCounter/DaysCounter";

const BuyNow = () => {
  const [selectedNode, setSelectedNode] = useState("super");
  const [nodeValue, setNodeValue] = useState(1);
  return (
    <div className="nodeBanner pt-5 mt-lg-3">
      <Container>
        <Row className="gy-5">
          <Col md={12} lg={6} className="mx-auto">
            <NodesHead
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
            />
            <div className="seed-round-card">
              <Row className="w-100 align-items-center py-4">
                <Col sm={12} md={4} className="text-center">
                  <img
                    src={require("../../Assets/Images/icons/final-logo.png")}
                    alt=""
                    style={{
                      width: "80px",
                    }}
                  />
                </Col>
                <Col sm={12} md={8}>
                  <div
                    className="card-header"
                    style={{
                      borderRadius: "20px",
                    }}
                  >
                    <p className="m-0">Purchase ends in</p>
                    <h3 className="m-0">
                      <DaysCounter />
                    </h3>
                  </div>
                </Col>
              </Row>
              <div className="card-body">
                <Row className="gy-4 gx-2">
                  <Col sm={12} md={4}>
                    <div className="leftCrd">
                      <p>Total Purchased Nodes</p>
                      <div className="text-center gap-3">
                        <span className="fs-4 fw-bold">0</span>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={8}>
                    <div className="rightCrd">
                      <p>Remaining Nodes</p>
                      <div className="node-progress-wrap m-0">
                        <div className="node-progress-line">
                          <span style={{ width: "0%" }}></span>
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-2">
                          <span>0%</span>
                        </div>
                      </div>
                      <p className="m-0">0/100 sold</p>
                    </div>
                  </Col>
                  <Col sm={12} md={12}>
                    <div className="centerCrd">
                      <div className="title">
                        <img
                          src={require("../../Assets/Images/icons/final-logo.png")}
                          alt=""
                        />
                        <div>
                          <h5 className="m-0">OXODISE</h5>
                          <p className="m-0">
                            1 Node ={" "}
                            {selectedNode === "elite"
                              ? "1,00,00"
                              : selectedNode === "rare"
                              ? "50,000"
                              : "2,00,00"}{" "}
                            OXODISE
                          </p>
                        </div>
                      </div>

                      <div className="minMaxContainer">
                        <button
                          type="button"
                          className="minusBtn"
                          onClick={() => setNodeValue(nodeValue - 1)}
                        >
                          <CiCircleMinus color="#fff" size={42} />
                        </button>
                        <div className="input">
                          <input type="number" value={nodeValue} />
                          <span className="fs-4">Node</span>
                        </div>
                        <button
                          type="button"
                          className="plusBtn"
                          onClick={() => setNodeValue(nodeValue + 1)}
                        >
                          <CiCirclePlus color="#fff" size={42} />
                        </button>
                        <button
                          type="button"
                          className="maxBtn"
                          onClick={() => setNodeValue(500)}
                        >
                          Max
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={12}>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <span>Price per coin</span>
                        <span className="gradientClr">0.10 $</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <span>Total Node Price</span>
                        <span>
                          {selectedNode === "elite"
                            ? "10,000"
                            : selectedNode === "rare"
                            ? "5,000"
                            : "20,000"}{" "}
                          $
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="align-items-center g-4">
                      <Col sm={12} md={5}>
                        <Button type="button" data-title="1">
                          Approve
                        </Button>
                      </Col>
                      <Col sm={12} md={2}>
                        <div className="text-center">
                          <FaPlay color="#838383" />
                        </div>
                      </Col>
                      <Col sm={12} md={5}>
                        <Button type="button" data-title="2" disabled>
                          Purchase
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <div className="text-center">
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center gap-2 text-decoration-underline"
                      >
                        Get more OXODISE <FiExternalLink color="#08A045" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={12} lg={12}>
            <div className="rare-node-card">
              <div className="card-column">
                <div className="d-flex align-items-center gap-3">
                  <img src={abcChainImg} alt="" className="icon" />
                  <h3 className="m-0">Purchased History</h3>
                </div>
              </div>
              <div className="card-column">
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-lg-5 px-4">
                  <div className="details">
                    <p>Staked coin</p>
                    <h5>0</h5>
                  </div>
                  <div className="details">
                    <p>My Positions</p>
                    <h5>0 Node pool</h5>
                  </div>
                  <div className="details">
                    <p>Maturity Period</p>
                    <h5>0 Months</h5>
                  </div>
                  <div className="details">
                    <p>Mature date</p>
                    <h5>-</h5>
                  </div>
                </div>
              </div>
              <div className="card-column">
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-lg-5 px-4">
                  <div className="">
                    <p className="m-0">Earned Reward</p>
                    <h3>0</h3>
                  </div>
                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                    <Button type="button">Connect Wallet</Button>
                    <Button type="button">Claim Rewards</Button>
                  </div>
                </div>
              </div>
              <div className="card-column">
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 px-4 px-lg-5">
                  <div className="position-relative">
                    See coin info <FiExternalLink color="#08A045" />
                  </div>
                  <div className="position-relative">
                    View contract <FiExternalLink color="#08A045" />
                  </div>
                  <div className="position-relative">
                    Recent Transection <FiExternalLink color="#08A045" />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BuyNow;
