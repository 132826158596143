import React from "react";
import { Col, Row } from "react-bootstrap";
import Bybit from "../../Assets/Images/join-us/bybit.svg";
import Mexc from "../../Assets/Images/join-us/mexc.svg";
import GateIo from "../../Assets/Images/join-us/gate-io.svg";
import Bitget from "../../Assets/Images/join-us/bitget.svg";
import Bingx from "../../Assets/Images/join-us/bingx.svg";
import Bitmart from "../../Assets/Images/join-us/bitmart.svg";
import Probit from "../../Assets/Images/join-us/probit.svg";
import Pionex from "../../Assets/Images/join-us/pionex.svg";
import Lbank from "../../Assets/Images/join-us/lbank.svg";
import { Link } from "react-router-dom";

const JoinUs = () => {
  const joinUs = [
    { icon: Bybit, link: "" },
    { icon: Mexc, link: "" },
    { icon: GateIo, link: "" },
    { icon: Bitget, link: "" },
    { icon: Bingx, link: "" },
    { icon: Bitmart, link: "" },
    { icon: Probit, link: "" },
    { icon: Pionex, link: "" },
    { icon: Lbank, link: "" },
  ];
  return (
    <Row className="align-items-center g-4">
      {joinUs?.map((item, index) => (
        <Col sm={12} md={6} lg={4}>
          <Link to={item.link} target="_blank" className="joinUsDiv">
            <img src={item.icon} alt={item.icon} className="img-fluid" />
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default JoinUs;
