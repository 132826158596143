import facebook from "../Assets/Images/icons/facebook.png";
import instagram from "../Assets/Images/icons/instagram.png";
import linkedin from "../Assets/Images/icons/linkedin.png";
import twitter from "../Assets/Images/icons/twitter.png";
import telegram from "../Assets/Images/icons/telegram.png";
import youtube from "../Assets/Images/icons/youtube.png";
import discord from "../Assets/Images/icons/discord.png";
import medium from "../Assets/Images/icons/medium.png";
import coinmarket from "../Assets/Images/icons/coinmarket.png";
import ROUTES from "./Routes";

export const socialMedia = [
  {
    id: 1,
    icon: facebook,
    link: "#",
  },
  {
    id: 2,
    icon: instagram,
    link: "#",
  },
  {
    id: 3,
    icon: linkedin,
    link: "#",
  },
  {
    id: 4,
    icon: twitter,
    link: "#",
  },
  {
    id: 5,
    icon: telegram,
    link: "#",
  },
  {
    id: 6,
    icon: youtube,
    link: "#",
  },
  {
    id: 7,
    icon: discord,
    link: "#",
  },
  {
    id: 8,
    icon: medium,
    link: "#",
  },
  {
    id: 9,
    icon: coinmarket,
    link: "#",
  },
];

export const useCaseList = [
  {
    id: 1,
    title: "OXODISE DeFi",
    description:
      "OXODISE DeFi offers users a complete range of decentralized finance tools, including staking where you can earn passive income with competitive APYs and flexible lock-up periods. Our yield farming feature allows you to boost returns with optimized strategies across diverse pools. Additionally, our platform features instant, low-cost token swaps powered by an automated market maker (AMM). OXODISE DeFi is your gateway to financial freedom.",
    btnText: "Coming Soon",
    link: ROUTES.ECOSYSTEM,
  },
  {
    id: 2,
    title: "OXODISE Bridge",
    description:
      "The OXODISE cross-chain bridge is designed with seamless interoperability, allowing users to effortlessly transfer assets between different networks without the hassle of complex processes. With high-speed transactions and minimal fees, our bridge ensures that your assets are always accessible and ready to be deployed across the DeFi landscape.",
    btnText: "Coming Soon",
    link: ROUTES.ECOSYSTEM,
  },
  {
    id: 3,
    title: "OXODISE Wallet",
    description:
      "The OXODISE Wallet is your all-in-one solution for managing digital assets seamlessly and securely. Whether you're trading, staking, or simply holding your tokens, OXODISE Wallet is built to offer convenience, flexibility, and security in the evolving world of decentralized finance.",
    btnText: "Coming Soon",
    link: ROUTES.ECOSYSTEM,
  },
  {
    id: 4,
    title: "OXODISE Pay",
    description:
      "OXODISE Pay lets you load your crypto and use it at any merchant, online or in-store, worldwide. Instantly convert your digital assets into a spendable balance that works like a traditional payment method. With fast transactions, low fees, and global acceptance, OXODISE Pay makes living off crypto simple and convenient, bringing the power of digital currency into your everyday purchases.",
    btnText: "Coming Soon",
    link: ROUTES.ECOSYSTEM,
  },
  {
    id: 5,
    title: "OXODISE Web3 Exchange",
    description:
      "Experience true financial freedom with the OXODISE Web3 Exchange, where you can trade cryptocurrencies in a borderless environment without the need for KYC. Our decentralized, non-custodial platform allows you to trade securely and anonymously, giving you full control over your assets at all times. Enjoy a seamless, private, and permissionless trading experience with access to a wide range of digital assets.",
    btnText: "Coming Soon",
    link: ROUTES.ECOSYSTEM,
  },
  {
    id: 7,
    title: "OXODISE Gaming",
    description:
      "With true ownership of in-game assets, tradable NFTs, and seamless crypto integration, OXODISE Web3 Gaming lets you not just play, but profit. Dive into the new era of gaming where fun meets financial rewards—where your skills and time are truly valuable.",
    btnText: "Coming Soon",
    link: ROUTES.ECOSYSTEM,
  },
  {
    id: 8,
    title: "OXODISE AI Trading Bot",
    description:
      "your AI-powered solution for automated profits. Simply connect your API from popular exchanges, and our advanced AI takes care of the rest. The OXODISE Trading Bot analyzes market trends, identifies profitable opportunities, and executes trades automatically—so you can earn without lifting a finger. our bot optimizes your strategy 24/7, ensuring you never miss a good trade. Once you join, just sit back and let the AI do the work while your portfolio grows. Experience stress-free, hands-off trading with OXODISE.",
    btnText: "Coming Soon",
    link: ROUTES.ECOSYSTEM,
  },
];
