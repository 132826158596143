import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../Assets/Images/logo.png';
import { socialMedia } from '../Configs/constants';
import ROUTES from '../Configs/Routes';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footerSec pt-5 pb-4'>
      <Container>
        <Row className='justify-content-between gx-4 gy-5 g-xl-5'>
          <Col lg={4} xl={3}>
            <img src={logo} alt='footerLogo' className='footerLogo mb-4' />
            <p className='mb-4'>OXODISE stands out from the crowd because of our broad and inclusive ecosystem.</p>

            <div className='footerSocials'>
              {socialMedia.map((item) => {
                return (
                  <a href={item.link} target='_blank' rel='noreferrer' key={item.id}>
                    <img src={item.icon} alt='' />
                  </a>
                )
              })}
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} lg={2} className='px-xxl-5'>
            <div className='footerLinks'>
              <h5 className='gradientClr'>Get Informed</h5>

              <Link to={ROUTES.ABOUT}>About Us</Link>
              <Link to={ROUTES.HOW_BUY}>How To Buy</Link>
              <Link to={ROUTES.EARN_ABC}>Earn Oxodise</Link>
              <Link to={ROUTES.BLOG}>Blog</Link>
            </div>

          </Col>

          <Col xs={6} sm={6} md={3} lg={3} className='px-xxl-5'>
            <div className='footerLinks'>
              <h5 className='gradientClr'>Ecosystem</h5>

              <Link to={ROUTES.HOME}>OXODISE DeFI</Link>
              <Link to={ROUTES.HOME}>OXODISE Bridge</Link>
              <Link to={ROUTES.HOME}>OXODISE Wallet</Link>
              <Link to={ROUTES.HOME}>OXODISE Pay</Link>
              <Link to={ROUTES.HOME}>OXODISE Web3 Exchange</Link>
              <Link to={ROUTES.HOME}>AOXODISE Gaming</Link>
              <Link to={ROUTES.HOME}>OXODISE AI Trading Bot</Link>
            </div>
          </Col>
          <Col sm={6} md={3} lg={2} className='px-xxl-5'>
            <div className='footerLinks'>
              <h5 className='gradientClr'>Information</h5>

              <Link to={ROUTES.HOME}>Term & Conditions</Link>
              <Link to={ROUTES.HOME}>Privacy policy</Link>
            </div>
          </Col>
          <Col sm={6} md={3} lg={2} className='px-xxl-4'>
            <div className='footerLinks'>
              <h5 className='gradientClr'>Contact Us</h5>

              <a href='mailto:info@coinOxodise.com'>info@coinoxodise.com</a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='copyright text-center p-2 p-sm-3 mt-4'>Copyright &copy; 2023 - 2024 OXODISE. All rights reserved.</div>
    </div>
  )
}

export default Footer