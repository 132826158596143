import React from "react";
import { Link } from "react-router-dom";
import ROUTES from "./../../Configs/Routes";
import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import audit from "../../Assets/Images/icons/audit.png";
import kyc from "../../Assets/Images/icons/kyc.png";
import whitepaper from "../../Assets/Images/icons/whitepaper.png";
import notepad from "../../Assets/Images/icons/notepad.png";
import bannerImg from "../../Assets/Images/hero-banner.svg";
import blockSAFU from "../../Assets/Images/blockSAFU.png";
import projectKYC from "../../Assets/Images/projectKYC.png";
import abcChainImg from "../../Assets/Images/abcChainImg.png";
import abcChangeImg from "../../Assets/Images/abcChangeImg.png";
import arrow from "../../Assets/Images/icons/arrow.png";
import trade from "../../Assets/Images/icons/trade.png";
import tokenomicsImg from "../../Assets/Images/tokenomicsImg3.svg";
import faqImg from "../../Assets/Images/faqImg.png";
import contactImg from "../../Assets/Images/contactImg.png";
import info1 from "../../Assets/Images/info1.png";
import info2 from "../../Assets/Images/info2.png";
import info3 from "../../Assets/Images/info3.png";
import info4 from "../../Assets/Images/info4.png";
import empowerImg from "../../Assets/Images/empowerImg.png";
import { socialMedia } from "./../../Configs/constants";
import UsecaseSlider from "./UsecaseSlider";
import PartnerSlider from "./PartnerSlider";
import NewsSlider from "../News/NewsSlider";
import RoadmapSlider from "./RoadmapSlider";
import NetworkTransData from "../Network/NetworkTransData";
import { IoDocumentTextOutline } from "react-icons/io5";
import IcoRounds from "./IcoRounds";
import JoinUs from "./JoinUs";
import videoSrc from "../../Assets/Images/banner/banner-video.mp4";

const Home = () => {
  return (
    <>
      <div className="bannerSec position-relative">
        <Container>
          {/* <Row className="align-items-center justify-content-center">
            <Col lg={6} className="py-5 text-center text-lg-start">
              <div className="pe-xl-5">
                <h2 className="bannerTitle pe-xl-5 me-xl-5">
                  Pioneering the <span className="gradientClr">Future</span> of
                  Decentralization
                </h2>
                <p className="bannerP my-4">
                  Industry-Focused Layer 2 Solutions Designed for the Unique
                  Needs of Each Sector.
                </p>
              </div>
              <div className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-lg-start gap-3">
                <Link to={ROUTES.HOME} className="themeBtn">
                  <img src={whitepaper} alt="" /> Light Paper
                </Link>
              </div>
            </Col>

            <Col lg={6}>
              <div className="bannerImg">
                <img src={bannerImg} alt="" />
                <video
                  src={videoSrc}
                  controls={false}
                  autoPlay
                  muted
                  loop
                  className="banner-video"
                ></video>
              </div>
            </Col>
          </Row> */}
          <Row className="align-items-center justify-content-center text-center">
            <Col lg={12} className="py-5 text-center">
              <div className="text-center">
                <h2 className="bannerTitle mx-auto">
                  Pioneering the <span className="gradientClr">Future</span> of
                  Decentralization
                </h2>
                <p className="bannerP  my-4">
                  Industry-Focused Layer 2 Solutions Designed for the Unique
                  Needs of Each Sector.
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Link to={ROUTES.HOME} className="themeBtn">
                  <img src={whitepaper} alt="" /> Light Paper
                </Link>
              </div>
              <video
                src={videoSrc}
                controls={false}
                autoPlay
                muted
                loop
                className="banner-background"
              ></video> 
            </Col>
          </Row>
          <div className="socialList">
            {socialMedia.map((item) => {
              return (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  key={item.id}
                >
                  <img src={item.icon} alt="" />
                </a>
              );
            })}
          </div>
        </Container>
      </div>

      {/* <div className='auditKycSec pt-150'>
        <Container>
          <Row className='justify-content-center g-4'>
            <Col md={10} lg={6} className='auditKycDiv'>
              <div>
                <img src={blockSAFU} alt='' />
                <h2 className='mainTitle'>AUDIT</h2>
                <p>BlockSAFU has audited and verified the legitimacy and robust security of the Oxodise Ecosystem and its smart contracts.</p>
              </div>
            </Col>
            <Col md={10} lg={6} className='auditKycDiv'>
              <div>
                <img src={projectKYC} alt='' />
                <h2 className='mainTitle'>KYC</h2>
                <p>BlockSAFU has confirmed the legitimacy of the Oxodise team through a comprehensive KYC verification process, ensuring verified project ownership and detecting no suspicious activities.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      <div className="auditKycSec pt-150">
        <Container>
          <Row className="w-100">
            <Col md={12} lg={8} className="mx-auto">
              <h2 className="bannerTitle text-center">
                Become a <span className="gradientClr">Validator</span> and Earn
                Long-Term <span className="gradientClr">Rewards</span>
              </h2>
              <p className="text-center mt-3">
                By purchasing a node and becoming a validator in the Oxodise
                network, you can earn consistent rewards for the next 15 years.
                validating transactions, and participating in governance, all
                while earning rewards from block rewards, transaction fees, and
                staking incentives. your investment can yield passive income and
                compounded returns over time. Join now and be part of a
                decentralized future while enjoying stable earnings!
              </p>
              <div className="mt-5 d-flex justify-content-center">
                <Link to={ROUTES.BUY_NODE} className="themeBtn headerBtn">
                  Buy Node <img src={arrow} alt="" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="abcChainSec pt-150">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs={10} sm={7} md={6} lg={5} xl={6} xxl={5}>
              <div className="abcChainImg text-center">
                <img src={abcChainImg} alt="abcChainImg" />
              </div>
            </Col>
            <Col
              lg={7}
              xl={6}
              className="abcChainTxt py-5 text-center text-lg-start"
            >
              <h3 className="mainTitle2 gradientClr">Oxodise BlockChain</h3>
              <p className="my-4">
                The OXODISE Chain is a powerful blockchain designed for diverse
                applications, offering advanced cryptographic security, Proof of
                Stake (PoS) consensus, and smart contract capabilities for fast
                and transparent transactions. It empowers developers and
                businesses with scalable, decentralized solutions, fostering
                innovation with trust and efficiency across industries...
              </p>
              <div className="d-flex flex-wrap justify-content-center justify-content-lg-start gap-3">
                <Link to={ROUTES.ECOSYSTEM} className="themeBtn">
                  Explore <img src={arrow} alt="" />
                </Link>
              </div>
            </Col>
          </Row>

          <div className="mt-xl-4">
            <NetworkTransData />
          </div>
        </Container>
      </div>

      <div className="infoSec pt-150">
        <Container>
          <h2 className="mainTitle text-center gradientClr">
            The Easiest Place For Everyone
          </h2>
          <p className="text-center mt-3 mb-4 mb-sm-5">
            We Simplified It All Because Everyone Deserves A Slice Of Smooth
            Financial Management
          </p>

          <Row className="gy-4">
            <Col lg={6}>
              <div className="infoDiv d-flex flex-wrap flex-sm-nowrap gap-2">
                <div className="infoImg">
                  <img src={info1} alt="" />
                </div>
                <div className="infoTxt">
                  <h4>Introduction To Oxodise Blockchain</h4>
                  <p>
                    Discover The Innovative OXODISE Blockchain platform,
                    revolutionizing decentralized applications and smart
                    contract for a secure and transparent future.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="infoDiv d-flex flex-wrap flex-sm-nowrap gap-2">
                <div className="infoImg">
                  <img src={info2} alt="" />
                </div>
                <div className="infoTxt">
                  <h4>Features Of Oxodise Blockchain</h4>
                  <p>
                    The blockchain supports robust smart contracts and provides
                    comprehensive tools for developers to build and scale
                    decentralized applications (dApps) with ease.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="infoDiv d-flex flex-wrap flex-sm-nowrap gap-2">
                <div className="infoImg">
                  <img src={info3} alt="" />
                </div>
                <div className="infoTxt">
                  <h4>Join The Oxodise Community</h4>
                  <p>
                    Be a part of the Oxodise movement and connect with
                    like-minded innovators, developers, and blockchain
                    enthusiasts. contribute to the growth of a decentralized
                    future.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="infoDiv d-flex flex-wrap flex-sm-nowrap gap-2">
                <div className="infoImg">
                  <img src={info4} alt="" />
                </div>
                <div className="infoTxt">
                  <h4>Oxodise Blockchain Applications</h4>
                  <p>
                    Decentralized Finance (DeFi): Powering secure and scalable
                    DeFi solutions like lending, staking, and trading with low
                    fees and fast transactions.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="pt-150">
        <div className="empowerSec pt-5 pb-4">
          <Container>
            <Row className="align-items-center justify-content-center gy-4">
              <Col lg={6}>
                <h2 className="mainTitle gradientClr">
                  Empowering Governance By The Community
                </h2>
                <p className="">
                  Operates on trusted, community-driven governance, ensuring
                  transparency, accountability and participation from its users.
                </p>
                {/* <Link to={ROUTES.ABOUT} className="themeBtn">
                  Explore Now <img src={arrow} alt="" />
                </Link> */}

                <div className="empowerDiv mt-4 mt-sm-5">
                  <h5 className="mb-3">
                    <img src={notepad} alt="" /> Transparency
                  </h5>
                  <p>
                    Oxodise is committed to full transparency in all operations.
                    From open governance to publicly verifiable smart contracts,
                    every aspect of our blockchain is designed to ensure trust,
                    accountability, and clarity. Our community-driven approach
                    ensures that decisions are made openly, with all information
                    accessible to users, reinforcing the integrity and
                    reliability of our ecosystem.
                  </p>
                </div>
              </Col>
              <Col xs={10} sm={8} md={7} lg={6}>
                <div className="empowerImg text-center">
                  <img src={empowerImg} alt="empowerImg" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="abcChangeSec pt-150">
        <Container>
          <h2 className="mainTitle text-center">OXODISE ECOSYSTEM</h2>
          <Row className="align-items-center justify-content-evenly text-center text-lg-start">
            <Col lg={6} xxl={5} className="py-4 abcChangeDiv">
              <h3 className="mainTitle2 gradientClr mb-4">
                "Empowering Innovation Through a Unified Ecosystem"
              </h3>
              <p className="mb-4">
                The Oxodise Ecosystem offers a comprehensive suite of blockchain
                solutions, bringing together decentralized finance (DeFi),
                enterprise tools, gaming, and more into a single, interconnected
                platform.Experience seamless integration, advanced security, and
                limitless possibilities within a robust and fully decentralized
                ecosystem designed for the future.
              </p>

              <Link to={ROUTES.ECOSYSTEM} className="themeBtn">
                Explore Our Ecosystem <img src={arrow} alt="" />
              </Link>
            </Col>
            <Col
              xs={10}
              sm={9}
              md={8}
              lg={6}
              xxl={5}
              className="order-first order-lg-last"
            >
              <div>
                <img src={abcChangeImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="useCaseSec pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-4">USE CASES</h2>
          <div>
            <UsecaseSlider />
          </div>
        </Container>
      </div>

      {/* <div className="exchangeSec pt-150">
        <Container>
          <h2 className="mainTitle text-center">TRADE ON EXCHANGE</h2>
          <p className="text-center my-4">
            Become a Oxodise Coin investor by purchasing it on leading crypto
            exchange platforms. Unlock a world of financial possibilities and
            navigate the dynamic trading landscape seamlessly. Elevate your
            trading experience with intuitive platforms and explore the
            potential for financial growth in the exciting realm of exchange
            trading.
          </p>
          <Row className="gy-5 justify-content-center">
            <Col sm={9} md={6} lg={4}>
              <div className="exchangeDiv">
                <h3>XT EXCHANGE</h3>
                <div className="d-flex justify-content-between p-3">
                  <div>
                    <p>Listing Date :</p>
                    <p>Listing Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>20 May 2024</p>
                    <p>$0.1</p>
                    <p className="gradientClr fw-bold">Live</p>
                  </div>
                </div>
                <Link to={ROUTES.HOME} className="themeBtn exchangeBtn">
                  Trade Now <img src={trade} alt="" />
                </Link>
              </div>
            </Col>
            <Col sm={9} md={6} lg={4}>
              <div className="exchangeDiv">
                <h3>LATOKEN EXCHANGE</h3>
                <div className="d-flex justify-content-between p-3">
                  <h4 className="w-100 text-center my-4">Announce Soon</h4>
                </div>
              </div>
            </Col>
            <Col sm={9} md={6} lg={4}>
              <div className="exchangeDiv">
                <h3>MEXC GLOBAL EXCHANGE</h3>
                <div className="d-flex justify-content-between p-3">
                  <h4 className="w-100 text-center my-4">Announce Soon</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      <div className="icoSec pt-150">
        <Container>
          <h2 className="mainTitle text-center">
            "Get Involved with Oxodise Funding Opportunities"
          </h2>

          {/* <div className="d-flex flex-wrap justify-content-center gap-3 my-4 my-xxl-5">
            <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
              Pre Sale
            </Link>
            <Link to={ROUTES.HOME} className="themeBtn">
              IDO Sale
            </Link>
            <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
              IEO Sale
            </Link>
          </div>

          <Row className="g-4 justify-content-center">
            <Col md={6} lg={4}>
              <div className="exchangeDiv">
                <h3 className="d-flex justify-content-between">
                  PHASE 1 <span>COMPLETE</span>
                </h3>
                <div className="icoDates d-flex justify-content-center gap-3 p-3 pb-2">
                  <Link to={ROUTES.HOME} className="themeBtn">
                    Start Date <span>25 Jan, 2024</span>
                  </Link>
                  <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
                    End Date <span>31 Jan, 2024</span>
                  </Link>
                </div>
                <div className="d-flex justify-content-between p-3">
                  <div>
                    <p>Token :</p>
                    <p>Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>35,000,000 Oxodise</p>
                    <p>$0.0400</p>
                    <p>Complete</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="exchangeDiv">
                <h3 className="d-flex justify-content-between">
                  PHASE 2 <span>COMPLETE</span>
                </h3>
                <div className="icoDates d-flex justify-content-center gap-3 p-3 pb-2">
                  <Link to={ROUTES.HOME} className="themeBtn">
                    Start Date <span>1 Feb, 2024</span>
                  </Link>
                  <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
                    End Date <span>5 Feb, 2024</span>
                  </Link>
                </div>
                <div className="d-flex justify-content-between p-3">
                  <div>
                    <p>Token :</p>
                    <p>Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>35,000,000 Oxodise</p>
                    <p>$0.0500</p>
                    <p>Complete</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="exchangeDiv">
                <h3 className="d-flex justify-content-between">
                  PHASE 3 <span>COMPLETE</span>
                </h3>
                <div className="icoDates d-flex justify-content-center gap-3 p-3 pb-2">
                  <Link to={ROUTES.HOME} className="themeBtn">
                    Start Date <span>6 Feb, 2024</span>
                  </Link>
                  <Link to={ROUTES.HOME} className="themeBtn themeBtn2">
                    End Date <span>10 Feb, 2024</span>
                  </Link>
                </div>
                <div className="d-flex justify-content-between p-3">
                  <div>
                    <p>Token :</p>
                    <p>Price :</p>
                    <p>Status :</p>
                  </div>
                  <div>
                    <p>35,000,000 Oxodise</p>
                    <p>$0.0600</p>
                    <p>Complete</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
          <div className="w-100 mt-4">
            <IcoRounds />
          </div>
        </Container>
      </div>

      <div className="partnerSec pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-4">
            "Launching Soon: Your Gateway to Leading Exchanges!"
          </h2>
          <p className="text-center mt-3 mb-4 mb-sm-5">
            We’re excited to announce the launch of Oxodise! Soon, you’ll be
            able to trade our Coin on top exchanges. Stay tuned as we finalize
            listings to bring you seamless trading opportunities with liquidity
            and reliability. Be among the first to join us as we take our
            project to the next level!
          </p>

          <JoinUs />
        </Container>
      </div>

      <div className="partnerSec pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-4">
            "Streamlined Access with{" "}
            <span className="gradientClr">Multi-Service</span> Integration"
          </h2>

          <PartnerSlider />
        </Container>
      </div>

      <div className="newsSlider pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-4">OXODISE IN THE NEWS</h2>

          <NewsSlider />
        </Container>
      </div>

      <div className="tokenSec pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-4">OXODISE Tokenomics</h2>

          <div className="contractAddress mb-5">
            Contract Address :{" "}
            <span>0X1C07B56D1765D15AA9D.....................</span>
          </div>

          <Row className="align-items-center justify-content-center">
            <Col xs={10} sm={8} md={7} lg={6} xxl={5}>
              <div className="tokenomicsImg text-center">
                <img src={tokenomicsImg} alt="tokenomicsImg" />
              </div>
            </Col>
            <Col lg={6} xxl={6} className="py-4">
              <Row className="g-4">
                <Col sm={6} className="tokenDiv">
                  <div>
                    <p>Token Name</p>
                    <h3 className="mainTitle2 mb-0">OXODISE Coin</h3>
                  </div>
                </Col>
                <Col sm={6} className="tokenDiv">
                  <div>
                    <p>Token Symbol</p>
                    <h3 className="mainTitle2 mb-0">OXE</h3>
                  </div>
                </Col>
                <Col sm={6} className="tokenDiv">
                  <div>
                    <p>Decimal</p>
                    <h3 className="mainTitle2 mb-0">18</h3>
                  </div>
                </Col>
                <Col sm={6} className="tokenDiv">
                  <div>
                    <p>Total Supply</p>
                    <h3 className="mainTitle2 mb-0">600 Million</h3>
                  </div>
                </Col>
              </Row>

              <div className="contractSlide mt-4">
                <ul>
                  <li>70% Mining Reward</li>
                  <li>10% Node & Seed Investor</li>
                  <li>5% Team & Advisory</li>
                  <li>5% Ecosystem</li>
                  <li>5% Liquidity(MM/Exchanges)</li>
                  <li>5% Airdrop & Campaign</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 text-center g-4">
            <Col xs={12}>
              <p className="mb-0">
                Minted (30%): Represents the initial 30% of the supply.
              </p>
              <p>
                Future Generation (70%): Represents the remaining 70% to be
                gradually generated over 15 years.
              </p>
            </Col>
            <Col xs={12}>
              <Link to={ROUTES.ECOSYSTEM} className="themeBtn">
                Learn More <img src={arrow} alt="" />
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="roadmapSec pt-150">
        <Container>
          <h2 className="mainTitle text-center">OUR ROADMAP</h2>

          <div>
            <RoadmapSlider />
          </div>
        </Container>
      </div> */}

      <div className="documentSec pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-4">DOCUMENTS</h2>

          <div className="d-flex flex-wrap justify-content-center gap-3">
            <Link to={ROUTES.HOME} className="themeBtn">
              <IoDocumentTextOutline /> Light Paper
            </Link>
            <Link to={ROUTES.HOME} className="themeBtn">
              <IoDocumentTextOutline /> White Paper
            </Link>
          </div>
        </Container>
      </div>

      {/* <div className="faqSec pt-150">
        <Container>
          <h2 className="mainTitle text-center mb-4">
            FREQUENTLY ASKED QUESTIONS
          </h2>
          <p className="text-center mb-5">
            All your Oxodise project-related questions & doubts are answered by
            our experts right here.
          </p>

          <Row className="justify-content-center g-4">
            <Col lg={7} xxl={6}>
              <Accordion className="faqAccordion" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header> What is Oxodise Coin?</Accordion.Header>
                  <Accordion.Body>
                    Oxodise Coin stands as an advanced cryptocurrency,
                    engineered to transform multiple industries through its
                    inclusive ecosystem. Harnessing the power of blockchain
                    technology, Oxodise Coin endeavors to tackle challenges
                    across diverse sectors such as gaming, news reporting,
                    supply chain management, and beyond. The provided solutions
                    prioritize transparency, security, and decentralization,
                    marking a revolutionary approach to industry dynamics.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How is Oxodise Coin different from other cryptocurrencies?
                  </Accordion.Header>
                  <Accordion.Body>
                    Oxodise Coin stands as an advanced cryptocurrency,
                    engineered to transform multiple industries through its
                    inclusive ecosystem. Harnessing the power of blockchain
                    technology, Oxodise Coin endeavors to tackle challenges
                    across diverse sectors such as gaming, news reporting,
                    supply chain management, and beyond. The provided solutions
                    prioritize transparency, security, and decentralization,
                    marking a revolutionary approach to industry dynamics.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How Can I Purchase Oxodise Coin?
                  </Accordion.Header>
                  <Accordion.Body>
                    Oxodise Coin stands as an advanced cryptocurrency,
                    engineered to transform multiple industries through its
                    inclusive ecosystem. Harnessing the power of blockchain
                    technology, Oxodise Coin endeavors to tackle challenges
                    across diverse sectors such as gaming, news reporting,
                    supply chain management, and beyond. The provided solutions
                    prioritize transparency, security, and decentralization,
                    marking a revolutionary approach to industry dynamics.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {" "}
                    What measures does Oxodise Coin take to ensure security and
                    privacy?
                  </Accordion.Header>
                  <Accordion.Body>
                    Oxodise Coin stands as an advanced cryptocurrency,
                    engineered to transform multiple industries through its
                    inclusive ecosystem. Harnessing the power of blockchain
                    technology, Oxodise Coin endeavors to tackle challenges
                    across diverse sectors such as gaming, news reporting,
                    supply chain management, and beyond. The provided solutions
                    prioritize transparency, security, and decentralization,
                    marking a revolutionary approach to industry dynamics.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    {" "}
                    What is the utility of the Oxodise Coin within its
                    ecosystem?
                  </Accordion.Header>
                  <Accordion.Body>
                    Oxodise Coin stands as an advanced cryptocurrency,
                    engineered to transform multiple industries through its
                    inclusive ecosystem. Harnessing the power of blockchain
                    technology, Oxodise Coin endeavors to tackle challenges
                    across diverse sectors such as gaming, news reporting,
                    supply chain management, and beyond. The provided solutions
                    prioritize transparency, security, and decentralization,
                    marking a revolutionary approach to industry dynamics.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    {" "}
                    I' m new to cryptocurrencies. Where can I learn more about
                    Oxodise Coin and its projects?
                  </Accordion.Header>
                  <Accordion.Body>
                    Oxodise Coin stands as an advanced cryptocurrency,
                    engineered to transform multiple industries through its
                    inclusive ecosystem. Harnessing the power of blockchain
                    technology, Oxodise Coin endeavors to tackle challenges
                    across diverse sectors such as gaming, news reporting,
                    supply chain management, and beyond. The provided solutions
                    prioritize transparency, security, and decentralization,
                    marking a revolutionary approach to industry dynamics.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col
              xs={10}
              sm={9}
              md={8}
              lg={5}
              xxl={5}
              className="order-first order-lg-last"
            >
              <div className="faqImg text-center">
                <img src={faqImg} alt="faqImg" />
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* <div className="contactSec pt-150">
        <Container className="px-sm-5 pt-lg-5 pb-5">
          <Row className="justify-content-center align-items-center g-4">
            <Col xs={10} sm={9} md={8} lg={6}>
              <div className="contactImg text-center">
                <img src={contactImg} alt="contactImg" />
              </div>
            </Col>
            <Col lg={6}>
              <Form className="contactForm text-center">
                <h2 className="mainTitle2 mb-4">NEED ANY HELP!</h2>
                <p className="mb-4">
                  Require assistance or have inquiries about Oxodise? Simply
                  complete the form, and we'll get back to you promptly.
                </p>

                <Form.Group className="mb-3" controlId="name">
                  <Form.Control type="text" placeholder="Enter Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Control type="number" placeholder="Phone" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Control as="textarea" rows={4} placeholder="Message" />
                </Form.Group>

                <Link to={ROUTES.HOME} className="themeBtn mx-auto mt-4">
                  Submit Now
                </Link>
              </Form>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default Home;
